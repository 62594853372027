import React from 'react';
import Layout from '../layouts/nl';
import iconDoubleRight from '../assets/images/icons/icon-double-right.svg';
import iconArrowRight from '../assets/images/icons/icon-arrow-right.svg';
import iconRightBlue from '../assets/images/icons/icon-double-right.svg';
import Scroll from './../components/Scroll';
import { Link } from 'gatsby'
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";
import { openUrl } from '../util/url';

export default class newsOverviewPage extends React.Component {

    apiEndPoint = process.env.GATSBY_API_URL;

    client = new ApolloClient({
        uri: `${process.env.GATSBY_API_URL}/graphql`,
    });

    state = {};

    limit = 7;

    start = 0;

    constructor(props) {
        super(props);
        this.state = {
            news: null
        }

        this.state.next = false;
        this.state.prevous = false;
        
    }

    componentDidMount() {
        this.getAllNews(this.start);
    }
    
    /**
     * Checks if object is empty. 
     */
    isObjectEmpty(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    /**
   * Get all news from response.
   */
    getAllNews(start) {
        if (start < 0) {
            return
        }
        if (start <= 0) {
            this.setState({ prevous: false });
        } else {
            this.setState({ prevous: true });
        }
        this.start = start;
        this.client.query({
            fetchPolicy: 'no-cache',
            query: gql`
              {
                newsnls(limit: ${this.limit}, start: ${start}, where: { active: true }, sort: "published:desc" ){
                    client
                    summary
                    source
                    img_heading
                    published
                    article_content
                    heading_image {
                        name
                        url
                        id
                      }
                    active
                    slug
                    id
                }
              }
            `
          }).then(
            (response) => {
            if (response.data.newsnls.length === 7) {
                response.data.newsnls.pop();
                this.setState({ news: response.data.newsnls });
                this.setState({ next: true });
            } else {
                this.setState({ news: response.data.newsnls });
                this.setState({ next: false });
            }
            }
          )
    }
    
    render() {
        let options = {month: 'long', day: '2-digit', year: 'numeric'};     
        return (
            <Layout>
                <header id="header" className="masthead news-overview-cover-photo"></header>

                <section className="container news-overview-wrapper content-wrapper">
                    <h2 className="news-overview-title">
                        Onze laatste activiteiten en investeringen
                    </h2>
                    <div className="news-overview-items-wrapper"> 
                        { 
                            (this.state.news === undefined || this.state.news === null || this.state.news.length === 0) ?
                                (
                                    <div></div>
                                ) :
                                (
                                    this.state.news.map((item, index) => {
                                        return (
                                            <Link to={'/nl/news-details/?' + item.slug} className="news-overview-item" key={index}>
                                                <div className="news-overview-item-primary">
                                                    {item.img_heading}
                                                </div>
                                                <div className="news-overview-item-secondary cursor--pointer">
                                                    <div onClick={() => openUrl(item.source_url)}>
                                                        {item.source || 'Persbericht'}, {new Intl.DateTimeFormat('nl-NL', options).format(new Date(item.published))}
                                                    </div>
                                                </div>
                                            </Link>
                                        );
                                    }
                                )
                            )
                        }                        
                        <div className="news-overview-pagination">
                            <div className={(this.state.prevous ? "pagination-item-prev" : "pagination-item-prev pagination-prev" )} onClick={() => this.getAllNews(this.start - 6)}>
                                <span href="/">
                                    <img src={iconArrowRight} alt=""/> Vorige pagina
                                </span>
                            </div>
                            <span className="vertical-separator"></span>
                            <div className={(this.state.next ? "pagination-item-next" : "pagination-item-next pagination-next" )} onClick={() => this.getAllNews(this.start + 6)}>
                                <span>
                                Volgend pagina <img src={iconRightBlue} alt=""/>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <Scroll type="id" element="header">
                    <div className="news-back back-to-top-wrapper">
                        <a href="?scroll-to=header">
                            <i className="fas fa-angle-double-up"></i>
                            <br />
                            <span className="back-to-top">Terug naar boven</span>
                        </a>
                    </div>

                </Scroll>
            </Layout>
        )    
    }
}
