export function parse(url, text, alternative) {
  try {
    const original = url;
    if (!url.includes('http://') && !url.includes('https://')) {
      url = `https://${url}`;
    }

    const {
      origin
    } = new URL(url);

    return {
      origin,
      failed: false,
      text: text || alternative,
      original: original,
      updatedUrl: url
    };
  } catch (e) {
    return {
      host: null,
      failed: true,
      text: text || alternative,
      original: null,
      updatedUrl: null
    }
  }
}

export function openUrl(url) {
  const parsed = parse(url);
  
  if (!parsed.failed) {
    window.open(parsed.updatedUrl, '_blank');
  }
}